import { RegisterPricingPageToolTips } from "@components/BuilderComponents/PricingPageToolTips";
import { RegisterBannerButtonLarge } from "@components/BuilderWrapperComponents/BannerButtonLarge";
import { RegisterCtaButtonPlayVideo } from "@components/BuilderWrapperComponents/CtaButtonPlayVideo";
import { RegisterEmailCaptureLeadsWrapper } from "@components/BuilderWrapperComponents/EmailCaptureLeadsWrapper";
import { RegisterEmailCaptureWBackground } from "@components/BuilderWrapperComponents/EmailCaptureWBackground";
import { RegisterEmailOnlyForm } from "@components/BuilderWrapperComponents/EmailOnlyFormBC";
import { RegisterGlobalBannerSection } from "@components/BuilderWrapperComponents/GlobalBannerSectionBC";
import { RegisterPrimaryBtn } from "@components/BuilderWrapperComponents/PrimaryBtnBC";
import { RegisterPrimaryBtnWithArrow } from "@components/BuilderWrapperComponents/PrimaryBtnWithArrowBC";
import { RegisterResourceGatedDownload } from "@components/BuilderWrapperComponents/ResourceGatedDownload";
import { RegisterResourceSliderWrapper } from "@components/BuilderWrapperComponents/ResourceSliderWrapper";
import { RegisterSignupFormDrip } from "@components/BuilderWrapperComponents/SignupFormDripWrapper";
import { RegisterSocialLinksWrapper } from "@components/BuilderWrapperComponents/SocialLinksWrapper";
import { RegisterCtaNew } from "../components/RedesignComponents/BuilderWrappers/Cta/Cta";
import {
  RegisterButtonVideo,
  RegisterVideoEmbedWrapper,
} from "../components/RedesignComponents/BuilderWrappers/VideoEmbedWrapper/VideoEmbedWrapper";
import { RegisterFAQsAccordion } from "@components/RedesignComponents/BuilderWrappers/FAQsAccordionWrapper/FAQsAccordionWrapper";
import { RegisterSectionWithMargin } from "../components/RedesignComponents/SectionWithMargin/SectionWithMargin";
import { RegisterSignUpFormWrapper } from "../components/RedesignComponents/SignUpFormWrapper/SignUpFormWrapper";
import { RegisterLottie } from "../components/RedesignComponents/BuilderWrappers/LottieWrapper/LottieWrapper";
import { RegisterFeatureCard } from "../components/RedesignComponents/BuilderWrappers/FeatureCardWrapper/FeatureCardWrapper";
import { RegisterButterFlyEffect } from "../components/RedesignComponents/BuilderWrappers/SocialProofWrapper";
import { RegisterFeatureDetail } from "../components/RedesignComponents/BuilderWrappers/FeatureModuleDetailWrapper";
import { RegisterVerticalTab } from "../components/RedesignComponents/BuilderWrappers/TextCarouselWrapper/TextCarouselWrapper";
import { RegisterTooltip } from "@components/RedesignComponents/BuilderWrappers/TooltipWrapper/TooltipWrapper";
import { RegisterIllustrationCard } from "@components/RedesignComponents/BuilderWrappers/IllustrationCard/IllustrationCard";
import { RegisterPageHeroAnchorLinkWrapper } from "@components/RedesignComponents/BuilderWrappers/PageHeroAnchorLinkWrapper/PageHeroAnchorLinkWrapper";
import { RegisterCompareMatrixPricingWrapper } from "@components/RedesignComponents/BuilderWrappers/CompareMatrixPricingWrapper/CompareMatrixPricingWrapper";
import { RegisterImageWrapper } from "../components/RedesignComponents/BuilderWrappers/ImageWrapper/ImageWrapper";
import { RegisterFooter } from "@components/RedesignComponents/BuilderWrappers/FooterWrapper/FooterWrapper";

export const RegisterComponentResourcePost = () => {
  RegisterGlobalBannerSection();
  RegisterEmailCaptureLeadsWrapper();
  RegisterSocialLinksWrapper();
  RegisterPrimaryBtn();
  RegisterPrimaryBtnWithArrow();
  RegisterEmailOnlyForm();
  RegisterBannerButtonLarge();
  RegisterCtaButtonPlayVideo();
  RegisterResourceGatedDownload();
  RegisterEmailCaptureWBackground();
  RegisterPricingPageToolTips();
  RegisterResourceSliderWrapper();
  RegisterSignupFormDrip();
  RegisterCtaNew();
  RegisterVideoEmbedWrapper();
  RegisterLottie();
  RegisterFeatureCard();
  RegisterButtonVideo();
  RegisterButterFlyEffect();
  RegisterFeatureDetail();
  RegisterVerticalTab();
  RegisterSectionWithMargin();
  RegisterFAQsAccordion();
  RegisterSignUpFormWrapper();
  RegisterTooltip();
  RegisterIllustrationCard();
  RegisterPageHeroAnchorLinkWrapper();
  RegisterCompareMatrixPricingWrapper();
  RegisterImageWrapper();
  RegisterFooter();
};
